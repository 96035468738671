.navigation-widget {
  $driver-width: 23px;
  $driver-height: 23px;

  position: relative;
  overflow: hidden;

  width: 100%;
  height: 100%;

  .guidance-overlay {
    width: 360px;
    height: 100%;
    float: left;
    background-color: rgb(42, 106, 13);

    box-shadow: 6px 0 4px -5px #333;
    color: #fff;
    position: relative;

    .guidance-overlay-content {
      position: absolute;
      top: 50%;
      transform: translateY(-70%);
      left: 0;
      right: 0;
      p {
        margin: 0;
      }
      .distance {
        font-size: 43px;
        line-height: 43px;
      }
      .direction, .measurement {
        color: rgba(250, 250, 250, 0.6);
      }
      .information {
        font-size: 18px;
        border-top: 1px solid rgba(250, 250, 250, 0.35);
      }

      .seperator {
        background-color: rgba(250, 250, 250, 0.6);
        width: 5px;
        height: 5px;
        border-radius: 5px;
        display: inline-block;
        margin: 0 5px 3px 5px;
      }
    }

  }

  .map-wrapper {
    height: 100%;
    width: calc(100% - 360px);
    &.full-width {
      width: 100%;
    }
    float: left;
    position: relative;

    .tomtom-map {
      height: 100%;
      width: 100%;
    }

    .driver-icon {
      position: absolute;
      width: $driver-width;
      height: $driver-height;
      top: calc(50% - #{$driver-height / 2});
      left: calc(50% - #{$driver-width / 2});
      background-size: $driver-width $driver-height;
      background-image: url('/vehicles-icons/road-supervisor-noarrow.svg');
      z-index: 10000;
      &.driving {
        background-image: url('/vehicles-icons/road-supervisor.svg');
      }
    }

    
  }
}
