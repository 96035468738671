.vehicle-breakdown-form-widget, .breakdown-replacement-form-widget {
  tr {
    .form-components-breakdown-place {
      height: 27px;

      .places-table {
        border: none;
        margin-top: 0rem !important;

        span.ember-power-select-selected-item {
          margin-left: 2px;
        }

        td.button-cell {
          padding: 0px;

          .places-inline-button {
            width: 20px;
            height: 21px;
            padding: 0 2px 0px 3px !important;
            font-size: $widget-button-font-size;

          }
        }

        div.ember-power-select-trigger {
          height: 22px;
        }
      }
    }

    .form-components-datetimeflatpickr .flatpickr-input {
      width: 89%;
    }
  }
}

