.dashboard-picker {
  .dashboard-picker-section {
    display: table;
    height: auto;
    min-height: 120px;

    @include tablet {
      display: table;
      height: auto;
      min-height: 120px;
    }

    @include desktop {
      display: table;
      height: auto;
      min-height: 120px;
    }

  }

  .dashboard-picker-section-title {
    font-weight: bold;
    font-size: 0.8em;
    background-color: #eee;

    align-items: center;
    padding: 0px 10px;
    border-bottom: 1px solid #eee;
  }

  .dashboard-picker-item {
    display: flex;
    align-items: center;

    padding: 10px;
    border-bottom: 1px solid #eee;

    &.is-open.is-clean {
      color: green;
    }

    .spacer {
      flex: 1 0 1px;
    }

    button {
      justify-self: flex-end;
    }
  }
}
