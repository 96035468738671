
.donut-chart-widget {
  .donut-chart-widget-subheader {
    padding-left: 2px;
    padding-bottom: 5px;
    border-bottom: 1px solid $icon-light-color;
    span {
      margin-left: 12px;
      font-size: 14px;
    }

    .donut-chart-widget-active-filter-button {
      font-size: 12px;
      padding: 5px 5px;
      background-color: transparent;
      border: 1px solid $icon-light-color;

      &:hover {
        background-color: $icon-light-color;
        color: white;
      }
    }
  }

  .donut-chart-widget-body {

      .donut-chart-container {
        position: relative;
        height: 100%;
        width: 100%;
      }
  }
}
