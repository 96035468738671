.add-new-route-side-drawer {

  .g-side-drawer-body {
    padding-top: 8px;
    background-color: white;
  }
}
.schedule-detail-info {
  display: flex;
  flex-direction: column;

  .schedule-title {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
  }
}