
.road-supervisor-modal {
  position: absolute;

  top: $nav-height;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 1000;
  background: black;

  .road-supervisor-title-container{
    position: absolute;
    left: 160px;
    top: - $nav-height;;
    right: 0;
    height: 50px;
    background: #263238;
  }
  .road-supervisor-title {
    position: absolute;
    left: 0;
    top: 11px;
    color: white;
    font-size: 18px;
  }

  .btn-close-road-supervisor {
    position: absolute;
    right: 20px;
    top: 5px;
  }

  .driving-disclaimer {

    h4 {
      margin-bottom: 15px;
    }

    max-width: 600px;
    background: white;

    border-radius: 8px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
