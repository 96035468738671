.form-components-travel-need {
  .ember-power-select-trigger {
    // width: 68% !important;
  }

  td.cell-label.table-label {
    font-weight: 500;
    font-size: 12px;
    height: 23px;
    text-align: left;
  }

  .travel-need-table {
    border: 0;
    margin-top: 0rem !important;

    td.cell-label {
      
    }

    .edit-cell {

    }
  }

  td.button-cell {
    padding: 0px;

    .remove-travelNeed-button {
      width: 20px;
      height: 21px;
      padding: 0 2px 0px 3px !important;
      font-size: $widget-button-font-size;
    }
  }

  .btn {
    padding: 0 0.40rem;
    font-size: 0.9rem;
  }

  td.cell-label.switch-cell-label {
    line-height: 1.2em;
  }
}
