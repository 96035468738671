.tree-widget {
  $max-depth: 20;

  $expand-button-width: 20px;
  $type-icon-width: 20px;
  $input-basis: 50px;

  font-size: 12px;
  font-weight: 700;

  .sp-replacer {
    padding: 0;

    .sp-preview {
      margin-right: 0;
    }

    .sp-dd {
      display: none;
    }
  }

  .tree-widget-loading-spinner {
    position: absolute;
    left: calc(50% - 8px);
    top: calc(50% - 5px);
  }

  .tree-widget-row {
    display: flex;
    cursor: default;

    height: 25px;
    line-height: 25px;
    padding-right: 5px;

    &:focus-within {
      background-color: $table-row-selected-color !important;
    }

    &.is-category {
      background: $sidedrawer-table-background-color;
      border-bottom: 1px solid $sidedrawer-table-border-color;
    }

    &.is-modified {
      color: green;
    }

    &.is-outdated {
    }

    @for $i from 0 through $max-depth {
      &.row-depth-#{$i} {
        $pixel-depth: $expand-button-width * $i;
        padding-left: $pixel-depth !important;
      }
    }

    .btn-expand {
      flex: 0 0 $expand-button-width;

      border: none;
      padding-left: 0;
      padding-right: 0;
      background: none;

      &:focus {
        outline: none;
      }
    }

    .tree-widget-row-icon {
      flex: 0 0 $type-icon-width;

      text-align: center;
    }

    .tree-widget-row-label {
      flex: 2 1 100px;
      max-width: 300px;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      padding-left: 2px;

      .search-highlighted {
        display: inline-block;
        color: blue;
        background-color: rgba(0, 0, 255, 0.2);
      }
    }

    .tree-widget-row-value {
      flex: 1 0 $input-basis;
      height: 90%;
      margin-top: 1px;

      .tree-widget-row-input-text {
        width: 100%;
        height: 100%;

        &:disabled {
          background-color: #fafafa;
        }

        &.search-highlighted {
          border-color: blue;
        }
      }
    }

    .tree-widget-row-controls {
      flex: 0 0 25px;

      .btn-delete {
        width: 100%;

        background: none;
        border: none;

        color: $widget-button-color;

        cursor: pointer;

        &:hover {
          color: $danger-color;
        }

        &:focus {
          outline: none;
        }
      }

      .btn-add {
        width: 100%;

        background: none;
        border: none;

        color: $widget-button-color;

        cursor: pointer;

        &:hover {
          color: $danger-color;
        }

        &:focus {
          outline: none;
        }
      }
    }

    // special for collections to add an object
    &.add-another {
      cursor: pointer;
      color: $alt-primary-color;

      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
