// colors
$primary-button: #7CB342; // links and buttons
$alt-primary-color: #689F38;

$header-dark-background: #263238;
$header-light-background: #eee;
$text: #303E44;

$grey: #F0F2F3;
$dds-green1: #7CB342; /* rgb(124, 179, 66) */
$dds-green2: #689F38; /* rgb(104, 159, 56) */
$dds-grey1: #303E44; /* rgb(38, 50, 56) */
$dds-grey2: #263238; /* rgb(38, 50, 56) */

$danger-color: #dc3545;

// OTP colors
$otp-ontime: #555e63;
$otp-danger: #d13730;
$otp-warning: #f09240;

// widget colors
$widget-shadow-color: #b3b3b3;
$widget-outline-color: #303E44;
$widget-drag-handle-color: #b7b7b7;
$widget-dropdown-shadow: #7d7a7a;

// button colors
$widget-button-color: #9DABB0;
$widget-button-focused-color: $primary-button;
$button-outline: $widget-shadow-color;
$button-highilighted-color: #797979;
$button-disabled-background: rgba(0,0,0,.1);
$button-disabled-alt-background: #92999D;

$icon-light-color: #9DABB0;
$icon-light-highlighted-color: $text;
$icon-dark-color: white;
$icon-dark-highlighted-color: $primary-button;

// side-drawer variables
$sidedrawer-background-color: white;

$sidedrawer-table-background-color: #F5F5F7;
$sidedrawer-table-border-color: #CCCED0;

$sidedrawer-header-icon-color: #A9ADB0;
$sidedrawer-font-color: #303E44;
$sidedrawer-input-outline-color: #D4D6D7;
$sidedrawer-input-font-color: #334147;
$sidedrawer-button-color: #263238;
$sidedrawer-button-outline: $sidedrawer-button-color;

$sidedrawer-title-font-size: 15px;

// form side-drawer colors
$form-widget-button-color: #AAADB0;
$form-widget-button-disabled-color: rgb(218, 218, 218);

// default css button variables
$primary: $primary-button;
$secondary: $icon-light-color;

// grid colors
$grid-background: #e6e7eb;
$grid-cell-color: #e0e1e4;
/* $grid-background: #e3e7e8;
$grid-cell-color: #d8d8d8; */
$grid-gutter-color: $grid-background;

// table colors
$table-body-text: #263238;
$table-background-color: #fff;
/* $table-body-text: #657175;
$table-background-color: #FAF9FA; */

/* $table-header-background-color: $grey;
$table-row-alt-background-color: #ebecf1; */
$table-header-background-color: white;
$table-column-header-background-color: rgb(245, 245, 247);

$table-row-alt-background-color: rgba(230, 231, 235, .4);

$table-row-late-color: #f2dede;
$table-row-pending-color: #fcf8e3;
$table-row-ahead-color: #dff0d8;
$table-row-selected-color: #7cb34245;
$table-row-left-border-width: 5px;
$table-header-left-border-width: 3px;
$table-cell-selected-color: #5d863145;

// fonts
$ui-font: "AvenirNext";
$widget-title-font-size: 13px;
$widget-title-mobile-font-size: 12px;
$widget-body-font-size: 12px;
$widget-body-mobile-font-size: 14px;
$widget-input-font-size: 12px;
$widget-input-mobile-font-size: 15px;
$widget-button-font-size: 13px;

// maximum supported browser width in pixels
$max-dashboard-width: 5000;
$max-dashboard-height: 2000;

// tile configuration options
$tile-sizes: 100, 150, 2000;
$tile-spacings: 2, 4, 6, 8, 16;

// grid configuration
$grid-color: #ddd;

// widget configuration
$widget-border-width: 0px;
$widget-header-height: 34px;
$widget-header-mobile-height: 46px;
$widget-header-padding: 6px;
$widget-header-mobile-padding: 10px;

$column-title-margin: 8px;

$nav-height: 50px;

$timeline-date-picker-width: 250; // must be 7x + 12, e.g. 292, 299, 306, etc.
$timeline-time-picker-width: 90; // TBD
