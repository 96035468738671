.system-config-side-drawer {
  .g-side-drawer-header {

    &.enable-border-bottom {
      border-bottom: 1px solid #ced4da
    }
  }
  .system-config-search-panel {
    background-color: $sidedrawer-table-background-color;
    display: flex;

    box-shadow: 0 0px 2px 0 rgba(0, 0, 0, .4);

    z-index: 5;

    .system-config-search-input {
      margin: 0px 10px 10px;
    }
  }
}
