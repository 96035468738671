$phone-width: 600px;
$tablet-width: 800px;
$desktop-width: 1024px;
$large-devices: 1920px;

/* 
##Device = Tablets, Ipads
##Screen = 800px to 1280px
*/
@mixin tablet {
    @media (min-device-width: #{$tablet-width}) and (max-device-width: #{$large-devices}) {
      @content;
    }
}

@mixin tablet-portrait {
  @media (max-device-width: #{$tablet-width}) and (orientation: portrait) {
    @content;
  }
}

@mixin tablet-landscape {
    @media (max-device-width: #{$large-devices}) and (orientation: landscape) {
      @content;
    }   
}

/* 
  ##Device = Desktops
  ##Screen = 600px to medium resolution desktops
*/
@mixin desktop-small-resolution {
    @media (min-width: #{$phone-width}) and (max-width: #{$tablet-width}) {
      @content;
    }
}


/* 
  ##Device = Desktops
  ##Screen = 800px to  high resolution desktops
*/
@mixin desktop-medium-resolution {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width}) {
      @content;
    }
}


/* 
  ##Device = Desktops
  ##Screen = 1200px to higher resolution desktops
*/
@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}