.header-wrapper {
  line-height: $nav-height;
  height: $nav-height;
  padding: 0 20px;

  &.is-reordering {
    background-color: #2874cb !important;
  }

  /* box-shadow: 0px 0px 10px 0px black; */
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  z-index: 1;

  &.is-light {
    background: $header-light-background;
    color: black;
  }

  &.is-dark {
    background: $header-dark-background;
    color: white;
  }

 #header-center {
    z-index: auto;

    @include tablet {
      .mobile-view {
      white-space: nowrap;
      display: flex;
      align-items: center;

      button {
        margin: 0 3px;
      }

     .tooltip-text {
        max-width: 380px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
    }
  }

    span {
      padding-right: 2em;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    button {
      &.btn-outline-primary {
        margin-right: 1em;
        &:focus {
          box-shadow: none;
        }

        &:active {
          background-color: $alt-primary-color;
          color: white;
          border-color: $alt-primary-color;
        }
      }
    }

    .reorder-stop {
      text-align: center;
      color: white;
      font-size: larger;
      // font-weight: 600;
      width: 55%;
      margin: 0 auto;

      &.is-hidden {
        display: none;
      }
    }
  }

  #header-left {
    height: 100%;
  }

  #header-right {
    > div {
      &:hover {
        .svg-header-icon {
          background-color: $icon-dark-highlighted-color;
          transition: background-color 0.2s;
        }
      }
    }

    .column {
      min-width: 30px;
      padding-right: 20px;
    }

    max-width: 500px;
    justify-content: flex-end;
    align-items: baseline;
    i {
      font-size: 1.2rem;
      color: white;
      &:hover {
        color: $icon-dark-highlighted-color;
        transition: color 0.2s;
        cursor: pointer;
      }
    }

    .ember-basic-dropdown-content-wormhole-origin {
      display: none;
    }

    .clear-active-context-btn {
      color: #363B42;
      border-color: transparent;
      background-color: white;
      font-weight: 500;
      font-size: 12px;
      flex-grow: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: normal;

      &:hover {
        background-color: rgba(255,255,255,0.8)
      }
      
      &:disabled {
        color: #AEAEAE;
        border-color: #B4B4B4;
        #grad {
          background-image: linear-gradient( #fff, #E0E0E0);
        }
        &:hover {
          cursor: not-allowed;
        }
      }
      
    }

    .svg-header-icon {
      position: relative;
      top: -2px;
      width: 19px;
      height: 20px;
      mask: url(/timeline.svg);
      -webkit-mask: url(/timeline.svg) no-repeat center center;
      -webkit-mask-size: 97% 95%;
      background-color: #fff;

      cursor: pointer;
    }

    .workspace-icon {
      // icon is centered vertically by parent's line-height, but sits 1px high
      position: relative;
      top: 1px;
    }

    .plus-icon {
      // icon is centered vertically by parent's line-height, but sits 1px high
      position: relative;
      top: 1px;
    }
  }
}

.header-action-button {
  // button should sit vertically _inside_ header bar with top/bottom margins
  height: 50px;
  line-height: 50px;
  // margin: 10px 0;

  &:focus {
    outline: none;
    box-shadow: 0 0 2px 2px $table-cell-selected-color;
    border-radius: 3px;
  }
}

.header-nav-dropdown-content {
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  font-family: $ui-font;
  font-size: $widget-body-font-size;
  background-color: rgba(38, 50, 56, 1);
  color: white;

  div {
    padding: 12px 20px;

    &:hover {
      cursor: pointer;
      background-color: $alt-primary-color;
      color: white;
      opacity: 1;
      transition: color 0.2s;
    }

    &[disabled] {
      background-color: $button-disabled-background;
      color: $grey;
    }
  }

  i {
    margin-right: 6px;
  }
}

.logo-image {
  background-image: url(/dds-logo.png);
  height: 34%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}

textarea.text-info-multi {
  color: white;
  background: #212e35;
  width: 450px;
  overflow: auto;
  border-radius: 4px;
  line-height: 20px;
  padding-left: 10px;
  text-align: left;
  vertical-align: inherit;
  border-color: transparent;
  max-height: 250px;
  border-radius: 4px;
}

.tooltip-overlay {
  position: fixed;
  top: $nav-height;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(38,50,56,10%);
  opacity: 0.3;
  z-index: 100;
}

.work-queue {
  font-size: 8px;
}
.ember-modal-wrapper.emd-static.emd-wrapper-target-attachment-center{
.tooltip-modal-styles-overlay {
  padding: 0;
  border-radius: 1;
  z-index: 110;
  &.ember-modal-overlay.translucent {
    background-color: rgba(38,50,56,0.2);
  }

  .ember-modal-dialog {
    background-color: transparent;
    box-shadow: none;
    transform: none !important;
    left: calc(50% - 200px);
    top: calc(50% -90px);

  .tooltip-modal {
    width: 400px;
    color: white;
    background: #263238;
    border-radius: 10px;
    box-shadow: 1px 2px 6px 1px rgba(0,0,0,0.4);
  
    &.single-error-list {
      height: 180px;
    }

    &.multi-error-list {
      height: 215px;
    }

    .tooltip-modal-header {
      width: 100%;
      height: 35px;
      background-image: linear-gradient( #334247, #171f22);
      font-size: medium;
      text-align: center;
      line-height: 2;
      padding-left: 6px;
      border-radius: 10px 10px 0px 0px;

      button {
        background-color: transparent;
        border: none;
        outline: none;
        float: right;
        padding-right: 15px;
        color: #ffffff;
      }
    }
  
    .tooltip-modal-body {    
      margin: 0 15px;
      padding-top: 17px;
      font-size: small;
      font-weight: 100;
      .status {
        width: 42px;
        height: 42px;
        margin-right: 8px;
        float: left;

        .warning-icon {
          width: 100%;
          height: 100%;
          background-image: url('/icons/ic-new-warning.svg');
          background-repeat: no-repeat;
        }
      }
      .tooltip-modal-button{
        margin-top: 12px;
        text-align: center;
        position: absolute;
        bottom: 27px;
        left: 182px;
        .btn-outline-secondary, .btn-outline-primary {
          color: white;
          border-color: white;
          line-height: 1.0;
          padding: 5px 20px;
          margin-right: 10px;
        }
      }
      .tooltip-text .error-list .error-item {
        margin-left: 27px;
      }
    }
  }
}
}
}