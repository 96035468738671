.form-components-onboard {
  margin-left: 11px;

  .replacement-onboard-trips-label {
    font-weight: normal;
    font-size: small;
    line-height: 15px;
    margin-top: 5px;
  }

  .replacement-onboard-trips-label2 {
    font-weight: bold;
    font-size: small;
  }

  .replacement-onboard-trips-checkbox {
    font-weight: normal;
    font-size: small;
    margin-top: 14px;
  }
}
