.section-body {
  flex: 1 1 auto;

  .section-layout {
    overflow: hidden;
    overflow-y: auto;
    border: 1px solid $sidedrawer-table-border-color;
    border-radius: 3px;
    border-spacing: 0;
    border-collapse: separate;
    min-width: 360px;
    width: 100%;
    vertical-align: middle;
    background-color: $sidedrawer-table-background-color;
    margin: 10px 0 0 0 !important;

    .section-row {
      display: flex;
      margin: 10px 0 0 0;
      padding-left: 10px;
      .form-components-select{
        width: 100%;
        padding: 0px 12px;
      }

      .form-components-text-area {
        width: 100%;
        padding: 0px 12px;
        font-size: 12px;
        textarea {
          width: 100%;
        }
      }
    }

    .hint{
      text-align: right;
      color: #b8b8b8;
      float: left;
      width: 100%;
      font-size: 12px;
    }

    .form-row {
      justify-content: flex-end;
      margin: 10px;
      .btn {
        &.default { 
        box-sizing: border-box;
        border: 1px solid #B4B4B4;
        border-radius: 2px;
        background: $secondary;
        box-shadow: 0 1px 2px 0 $secondary;
        color: #363B42;
        color: #000;

        &:hover{
          opacity: .75;
        }

        }

        &.success { 
          box-sizing: border-box;
          border: 1px solid #B4B4B4;
          border-radius: 2px;
          background: $primary;
          box-shadow: 0 1px 2px 0 $secondary;
          color: #FFFFFF;
          &:hover{
            opacity: .75;
          }
        }

      }
    }

      div {
        &.header {
          user-select: none;
          display: flex;
          justify-content: end;
          align-items: baseline;
          align-content: baseline;
          padding-left: 10px;

          .sub-header {
            h4 {
              font-size: 12px;
              font-weight: 600;
              padding: 2px 5px 2px 0px;
              margin-top: 8px;
              margin-bottom: 0px;
            }
          }
          

          .fa-sort-down {
            transform: rotate(0deg);
            transition: transform .1s linear;
            width: 1.2rem;
            height: 0.5rem;

            &:before {
              position: relative;
              top: -.23rem;
              left: 0.6rem;
            }
          }

          &.is-closed {
            .fa-sort-down {
              transform: rotate(-90deg);
              top: 0.4rem;
              left: 0.2rem;
            }
          }
        }
      }
  }

}