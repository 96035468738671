.timeline-picker-dropdown {
  position: absolute;
  top: $nav-height;
  font-family: $ui-font;
  width: 100%;
  right: 0px !important;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, .2);
  z-index: 100;

  .timeline-picker {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 54px;
    flex-grow: 0;
    flex-shrink: 0;

    background: $sidedrawer-table-background-color;

    padding: 15px 10px;

    .controls-row {
      display: flex;
      color: white;
      height: 24px;

      .spacer {
        flex: 1 1 0;
      }

      .date-picker-wrapper {
        flex: 0 0 ($timeline-date-picker-width * 1px);
        margin: 0 5px;
      }

      .time-picker-wrapper {
        flex: 0 0 ($timeline-time-picker-width * 1px);
        margin: 0 5px;
      }

      .relative-checkbox-section {
        margin: 0 5px;
        flex: 0 1 auto;
        display: inline-block;
        color: $header-dark-background;
        font-size: 12px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        label {
            display: block;
            padding-left: 15px;
            text-indent: -15px;
            margin: 1px 3px 0 0px;
        }

        input {
            width: 13px;
            height: 13px;
            padding: 0;
            margin:0 3px;
            vertical-align: center;
            position: relative;
            top: 1px;
            *overflow: hidden;
        }

        label.greyed {
          color: #aaa;
        }
      }

      .btn {
        font-size: 12px;
        font-weight: 600;
        height: 24px;
        padding: 0 12px;
        margin: 0px 3px;
      }

      .btn-primary {
      }

      .btn-outline-primary {

      }

      .zoom-controls {
        justify-self: end;
      }

      .close-button {
        justify-self: end;
      }
    }

    .tuner-row {
      overflow-x: auto;
      height: 100px;

      .bar {
        position: relative;
        width: 4000px;
        height: 100%;
      }
    }

    .time-scale-cell {
      text-align: center;
    }

    .time-bar-increment {
      height: 15px;
      border: solid #333;
      border-width: 0 1px 1px 1px;
    }

    .time-bar-operating-box {
      position: absolute;
      height: 15px;
      background-color: green;
      border-style:outset;
      border-radius: 3px;
    }

    .time-bar-handle-left {
      position: absolute;
      z-index: 2;
      height: 60px;
      width: 25px;
      border: solid green;
      border-width: 3px 0 3px 3px;
    }

    .time-bar-handle-right {
      position: absolute;
      z-index: 2;
      height: 60px;
      width: 25px;
      border: solid green;
      border-width: 3px 3px 3px 0;
    }

    .time-bar-now-marker {
      position: absolute;
      height: 60px;
      width: 1px;
      border: solid red 2px;
      z-index: 2;
    }

    .time-bar-operating-box-background {
      background-color: white;
      height: 15px;
    }
  }
}
