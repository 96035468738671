.map-filter {
  .map-filter-subheader {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, .5);
    margin-bottom: 10px;

    .layer-subheader-section {
      padding: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;

      label {
        flex: 0 0 auto;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;
        margin-left: 10px;
        margin-right: 5px;
      }

      .ember-power-select-trigger {
        flex: 1 1 auto;
      }
    }

    .visibility-subheader-section {
      padding: 5px 5px 5px 5px;
      display: flex;
      flex-direction: row;

      .side-drawer-checkbox {
        margin: 3px 10px 3px 50px;
        flex: 0 0 auto;
        display: flex;
        align-items: center;

        label {
          padding-left: 5px;
          margin-bottom: 0;
          font-size: 13px;
          font-weight: 500;
        }
      }

      .map-layer-transparency {
        margin: 3px 10px;
        flex: 1 1 auto;
        align-items: center;

        display: flex;

        label {
          flex: 0 0 auto;
          margin-bottom: 0;
          padding-right: 4px;
          margin-right: 4px;

          font-size: 13px;
          font-weight: 500;
        }

        .ui-slider {
          flex: 1 1 auto;
        }
      }
    }
  }

  .map-legend-filter {
    margin: 5px 10px 5px 5px;

    #map-legend-table {
      padding: 5px 10px;

      border: 1px solid $sidedrawer-table-border-color;
      border-radius: 3px;
      border-spacing: 0;
      border-collapse: separate;

      background-color: $sidedrawer-table-background-color;

      td {
        font-size: 12px;
      }
    }
  }

  thead {
    margin-bottom: 10px;
    h4 {
      font-size: 13px;
      font-weight: 600;
      margin: 8px 0;
    }
  }

  .map-label-preview {
    margin: 5px 10px 5px 5px;

    #map-filter-preview {
      font-size: 13px;
      padding: 5px 10px;

      border: 1px solid $sidedrawer-table-border-color;
      border-radius: 3px;
      border-spacing: 0;
      border-collapse: separate;

      background-color: $sidedrawer-table-background-color;

      tbody {
        margin: 0px 10px 10px 10px;
        padding: 5px;
        border: 1px solid #ccced0;
        display: block;
      }
    }
  }

  .filter-settings {
    margin: 5px 10px 5px 5px;

    .filter-settings-table {
      padding: 5px 10px;

      border: 1px solid $sidedrawer-table-border-color;
      border-radius: 3px;
      border-spacing: 0;
      border-collapse: separate;

      background-color: $sidedrawer-table-background-color;

      th {
        &.filter-type-column {
          font-size: 12px;
          font-weight: 600;
        }

        &.label-column {
          font-size: 12px;
          font-weight: 600;
          padding: 2px 2px 2px 4px;
        }

      }

      .sortable-item {
        transition: all .125s !important;
        position: relative;
    
        &.is-dragging {
          transition-duration: 0s;
          background: $sidedrawer-table-background-color;
          z-index: 10;
        }
    
        &.is-dropping {
          background: $sidedrawer-table-background-color;
          z-index: 10;
        }
      }

      .reorder-column {

      }

      .filter-type-column {
        width: 100px;
        padding-left: 8px;
      }

      .filter-value-column {
          padding-bottom: 3px;
        .header-button-outline {
          float: right;
          border-radius: 2px;
          background-color: $sidedrawer-table-background-color;
          border-color: rgba(38, 50, 56, .2);

          padding-top: 3px;
          padding-bottom: 0px;
        }
      }

    }
  }

  .slider {
    width: 168px;
  }

  .slider-track {
    top: 63% !important;
  }

  .slider-handle {
    left: 50%;
    width: 15px;
    height: 15px;
  }

  .slider-selection {
    height: 3px !important;
  }

  .slider-track-high {
    height: 3px !important;
  }
}
