
@mixin edit-input() {
  font-size: $widget-body-font-size;
  display: block;
  width: 100%;
  padding: 3px 7px 0 7px;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
  border-radius: 3px;
  outline: none;
}

// Overrides for ember cli addons:

.ember-power-select-options {
  font-size: $widget-input-font-size;

  .ember-power-select-option[aria-current="true"] {
    background-color: $alt-primary-color;
  }
}
