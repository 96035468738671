.application-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header-nav-wrapper {
    flex: 0 0 $nav-height;
    z-index: 1;
  }

  .content-wrapper {
    flex: 1 0 0;
    overflow: auto;
  }
}
