.toggle-switch {
  display: flex;
  align-items: center;

  .track {
    position: relative;
    box-sizing: border-box;
    height: 16px;
    width: 38px;
    margin: 0 8px;
    border: 1px solid #CCCED0;
    border-radius: 10px;
    background-color: #FFFFFF;
  }

  .button {
    height: 20px;
    width: 20px;
    background-color: #568F2B;
    box-shadow: 0 2px 4px 0 rgba(131,131,131,0.5);
    border-radius: 10px;
    position: absolute;
    top: -2px;
    left: -2px;
    right: auto;
  }

  .label {
    font-size: 14px;
    cursor: pointer;
  }

  &.is-active .button {
    left: auto;
    right: -2px;
  }
}
