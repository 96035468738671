.secondary-window {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, .25);
  z-index: 1000;

  .secondary-window-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    min-width: 80vw;
    max-height: calc(80vw - #{$nav-height});
    height: 85%;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    border-radius: 5px;
    background-color: white;

    @include desktop {
      height: 85%;
    }

    @include tablet {
      height: 70%;
    }

  }

  .secondary-window-header {
    flex: 0 0 40px;

    display: flex;
    align-items: center;

    padding: 0 10px;

    border-bottom: 1px solid #eee;
    font-weight: bold;

    .spacer {
      flex: 1 1 1px;
    }

    .close-button {
      background: none;
      border: none;
      color: $widget-button-color;

      &:hover {
        color: $button-highilighted-color;
      }

      &:focus {
        outline: none;
        color: $widget-button-focused-color;
      }
    }
  }

  .secondary-window-body {
    flex: 1 0 auto;
    height: 90%;
    display: flex;
    flex-direction: column;

    overflow-y: auto;

    @include desktop {
      height: 90%;
    }

    @include tablet {
      height: 90%;
    }

    .loading-spinner {
      flex: 1 0 100px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
