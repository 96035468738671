.form-components-subscriptions {

  .subscriptions-table {
    .cell-label {
      min-width: 56px;
    }

    br {
      content: "";
      margin: 2em;
      display: block;
      font-size: 24%;
    }
    .subscription-separation-cell {
      input.form-control.countInput {
        width: 78px;
      }
    }

    label {
      &.ember-radio-button {
        display: -webkit-inline-box;
        margin-bottom: 0;
        line-height: 1;
        width: 100%;

        label {
          &.radio-button-1-label {
            margin-bottom: 0;
            line-height: 1.4;
            font-size: 14px;
            height: 100%;
            padding: 3px 0 1px 10px;
            text-align: left;
            vertical-align: middle;
            width: 44px;
          }

          &.radio-button-2-label {
            margin-bottom: 0;
            line-height: 1.4;
            font-size: 11px;
            height: 100%;
            padding: 3px 0 0 3px;
            text-align: left;
            vertical-align: middle;
            width: 66px;
            white-space: nowrap;
          }
        }

        input.form-control.countInput {
          width: 66px;
        }

        .monthly-wom-select {
          width: 96px;
          padding-right: 3px;
        }

        .monthly-dow-select {
          width: 96px;
        }
        p.monthly-wom-line {
          display: flex;
          margin-bottom: 2px;
        }
        div.monthly-wom-line {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 5px;
        }
      }

      &.ember-radio-button.radio-button-input {
        width: 21px;
        margin-left: 15px;
        -webkit-box-align: center;
      }
    }

    .radio-button-group {
      display: flex;
      margin-bottom: 0;
      align-items: center;
    }

    span.subscription-end-option {
      min-width: 117px;
    }

    td.subscription-separation-label {
      min-width: 99px;
      font-size: 14px;
      padding-left: 18px;
    }

    td.subscription-recur-on-label {
      font-size: 14px;
      height: 100%;
      padding: 3px 0 1px 17px;
      text-align: left;
      vertical-align: middle;
    }

    input[type="checkbox"] {
      margin-left: 7px;
      margin-top: 8px;
    }

    .subscription-frequency {
      width: 100px;
      float: right;
      margin-right: 10px;
    }
    
    td.cell-label-frequency {
      padding-left: 100px;
    }

    .subscription-end {
      display: flex;
      .subscription-end-option {
        width: 45%
      }
      input {
        width: 50%
      }
    }

    .onoffswitch.subscription-recurring {
      margin-left: 10px;
    }

  }
}
