// These are styles for the side drawer content, e.g. system config. For modal
// or container styling, see `side-drawer-modal.scss`

.side-drawer {
  display: flex;
  flex-direction: column;
  height: 100%;

  input[type='checkbox'] {
    font-size: 1rem;
  }

  input[type='number'] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }

  .side-drawer-header {
    padding: 0 13px;
    margin: 0;
    background-color: $sidedrawer-table-background-color;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .side-drawer-title {
      margin: auto 0;
      font-size: $sidedrawer-title-font-size;
      font-weight: 600;
    }

    .side-drawer-buttons {
      flex-grow: 0;
      flex-shrink: 0;
      line-height: 1.1;
      cursor: default;

      .side-drawer-button {
        padding: 8px 0;
        margin-left: 12px;
        background-color: transparent;
        color: $form-widget-button-color;
        border: none;
        cursor: pointer;
        outline: none;

        &:hover {
          transition: color 0.2;
          color: $primary-button;
        }

        &:disabled {
          color: $form-widget-button-disabled-color !important;
          &:hover {
            color: $form-widget-button-disabled-color !important;
            cursor: default;
          }
        }

        i {
          font-size: 1em;
        }
      }
    }
  }

  .side-drawer-body {
    display: flex;
    flex-direction: column;

    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: calc(100% - 70px - 34px);
    padding: 0;

    overflow: hidden;
    overflow-y: auto;

    color: $text;
    font-family: $ui-font;

    .side-drawer-subheader {
      width: 100%;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, .5);
      z-index: 5;
      margin: 0;
    }

    .side-drawer-table-wrapper {}

    .side-drawer-section {
      margin: 0 0 7px 0;

      h5 {
        margin: 20px 0 5px 0;
      }

      label {
        width: 120px;
        display: inline-block;
        float: left;
        &.disabled {
          color: grey;
        }
      }

      .ui-slider {
        margin-right: 10px;
      }

      .ember-power-select-trigger {
        display: inline-block;
        width: calc(100% - 125px);
        margin-left: 5px;
      }

      input[type='number'] {
        display: inline-block;
        width: 65px;
        // text-align: center;
        margin-left: 5px;
      }

      .side-drawer-checkbox {
        label {
          width: auto;
          float: none;
        }
        font-size: 13px;

        .ember-checkbox {
          margin-right: 5px;
        }
      }

      .side-drawer-number-input {
        label {
          width: 120px;
          float: none;
        }
        font-size: 13px;
      }
    }

    .side-drawer-multi-select-section {
      label {
        width: 120px;
        display: inline-block;
        float: left;
        &.disabled {
          color: grey;
        }
      }

      .ember-power-select-trigger {
        display: inline-block;
        width: 30%;
        margin-left: 5px;
      }
    }
  }

  b {
    font-weight: 600;
  }
}
