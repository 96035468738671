.form-components-permission {

  .permissions-table {
    .sub-header {
      display: flex;
      justify-content: flex-end;

      .allow-label {
        font: 18px bold;
        flex-basis: 52%;
      }
    }

    .permission-row {
      display: flex;
      align-items: center;
      align-content: baseline;
      margin-top: 1em;

      .cell-label {
        font-size: 16px;
        white-space: nowrap;
        order: 2;
        padding: 0;
        flex: 0 2;
      }

      .permission-input {
        order: 1;
        flex: 0 1 15%;
      }
    }

  }
}
