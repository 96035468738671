.about-adept-styles-overlay .ember-modal-dialog {
  padding: 0;
  border-radius: 1;

  .about-adept {
    width: 400px;
    height: 552px;
    color: white;
    background: #334247;
  
    .about-adept-header {
      width: 100%;
      height: 35px;
      background: #171F22;
      font-size: medium;
      text-align: center;
      line-height: 2;
      padding-left: 6px;

      button {
        background-color: transparent;
        border: none;
        outline: none;
        float: right;
        padding-right: 15px;
        color: #ffffffd1;
      }
    }
  
    .adept-ui-version {
      margin-left: 28px;
      padding-top: 2px;
      font-size: small;
      font-weight: 400;
    }

    .adept-ui-copyright {
      margin-left: 28px;
      font-size: small;
      font-weight: 100;
      margin-right: 15px;
      padding-bottom: 9px;
      padding-top: 11px;
    }

    .about-logo-image {
      background-image: url(/dds-logo.png);
      height: 3%;
      width: 23%;
      background-repeat: no-repeat;
      background-size: contain;
      margin: 14px 0 0 27px;
    }
  
    .about-adept-body {    
      margin-left: 28px;
      padding-top: 8px;
      font-size: small;
      font-weight: 100;

      .about-adept-versions-header {
        margin-top: 27px;
        font-weight: 400;
      }

      .about-adept-versions {
        margin-top: 8px;
        width: 94%;
        font-size: small;
        font-weight: 100;
        height: 169px;
        background: #1D262B;
        border: 1px solid black;

        .scroll {
          max-height: 167px;
          overflow: auto;
          margin-left: 13px;
        }

        /* width */
        .scroll::-webkit-scrollbar {
          width: 10px;
        }

        /* Track */
        .scroll::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 1;
        }

        /* Handle */
        .scroll::-webkit-scrollbar-thumb {
          background: #7F8385;
          border-radius: 4px;
        }

        /* Handle on hover */
        .scroll::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

        table, td {
          border: 0px;
          font-size: small;
          margin-top: 5px;
          padding-bottom: 30px;

          th {
            font-weight: 100;
            width: 150px;
          }
  
          th:first-child {
            font-weight: 400;
            width: 75%;
          }
        }
      }
    }
  }
}
