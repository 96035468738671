.is-under-capacity {
  color: $danger-color;
}

.assign-rvd-side-drawer {
  height: inherit;
  .g-side-drawer-body {
    padding-top: 8px;

    .ember-light-table {
      height: 200px;

      .lt-row {
        &.is-disabled {
          background-color: lightgrey !important;
          color: grey;
        }
      }

    }

    .assign-rvd-layout .g-side-drawer-panel-body {
      .vehicle-search-row {
        font-size: 14px;
        font-weight: bold;
        display: flex;
        justify-content: flex-end;   
        line-height: 2;       

        input.vehicle-search-box {
          width: 17%;
          line-height: 0;
          border-color: lightgrey;
          border-right: 0;
          height: 26px;
          margin: 0 0 7px 8px;
        }

        button.vehicle-search-button {
          margin-right: 8px;
          display: inline-block;
          font-size: 18px;
          background-color: inherit;
          border: none;
          cursor: pointer;
          outline: none;
          border: 1px solid lightgrey;
          background-color: white;
          height: 26px;
          border-left: 0;
          line-height: initial;

          i.fa.fa-times-circle.active-button {
            opacity: 30%;
          }
        }
      }
    }
  }

  .ember-light-table .lt-column .lt-sort-icon {
    margin-right: 10px;
  }

}

.table-vehicle-header-column {
  display: flex;

  .column-label {
    flex-basis: 30px;
    flex-grow: 1;
    flex-shrink: 1;
    line-height: 23px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .column-controls {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 12px;
    padding-top: 4px;
    white-space: nowrap;
  }
}

