.no-show-approval-form{

  .form-widget-header-wrapper{
    z-index: 5;
  }

  .form-widget-subheader {
    padding: 5px 24px;
    z-index: 5;    
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    .readonly-label{
      display: block;
      padding-right: 10px;
      white-space: nowrap;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .g-side-drawer-body {
    padding-left: 10px;
    padding-right: 10px;
    height: 1000px;
    background-color: white;

    font-size: 12px;
    .section-body {
      flex: 1 1 auto;
      margin-top: 16px;
    
      .no-show-form-layout {
        .section-row {
          display: flex;
          align-items: flex-start;
          margin: 10px 0 0 0;
          padding-left: 10px;

          span {
            flex: 1 0 100px;          
          }

          .form-components-text{
            flex: 1 1 500px;
            padding: 0px 12px;

            &:disabled {
              color: $icon-light-color;
              border-color: $button-outline;
              background-color: $button-disabled-background;
              &:hover {
                color: $icon-light-color;
              }
            }
          }

          .form-components-select{
            flex: 1 1 auto;        
            padding: 0px 12px;
          }
    
          .form-components-text-area {
            width: 100%;
            padding: 0px 12px;
            font-size: 12px;
            flex: 1 1 auto;         
            textarea {
              flex: 1 0 auto;
              width: 100%;        
            }
          }
        }
    
        .hint{
          text-align: right;
          color: #b8b8b8;
          float: left;
          width: 100%;
          font-size: 12px;
        }
    
        .form-row {
          justify-content: flex-end;
          margin: 10px;
          .btn {
            &.default { 
            box-sizing: border-box;
            border: 1px solid #B4B4B4;
            border-radius: 2px;
            background: $secondary;
            box-shadow: 0 1px 2px 0 $secondary;
            color: #363B42;
            color: #000;
    
            &:hover{
              opacity: .75;
            }
    
            }
    
            &.success { 
              box-sizing: border-box;
              border: 1px solid #B4B4B4;
              border-radius: 2px;
              background: $primary;
              box-shadow: 0 1px 2px 0 $secondary;
              color: #FFFFFF;
              &:hover{
                opacity: .75;
              }
            }
    
          }
        }
  
      }
    
    }

  }

}