.form-widget {
  &.breakdown-replacement-form-widget {
    .cell-label {
      min-width: 130px;
    }

    .form-components-datetimeflatpickr .flatpickr-input {
      width: 100%;
    }
  }
}
