.road-supervisor-dashboard-info {
  height: auto;

  @include desktop {
    .driving-disclaimer {
      max-width: 60%;
    }

    .row-btn {
      width: 100%;

      .btn-agree {
        width: 60%;
      }
    }
  }

  .driving-disclaimer {
    h4 {
      margin-bottom: 22px;
      font-size: 42px;
      font-weight: bold;
      padding-top: 5%;
      text-align: center;
    }

    p {
      text-align: justify;
    }

    max-width: 80%;
    background: transparent;
    text-align: left;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
  }

  .row-btn {
    width: 100%;
    background-color: #fff;
    margin-top: 42px;
    padding-top: 14px;
    padding-bottom: 14px;
    text-align: center;
    box-shadow: 1px 1px 7px 3px #88888869;

    .btn-agree {
      margin-top: 0;
      margin-right: auto;
      margin-bottom: 0;
      margin-left: auto;
      width: 80%;
      color: #fff;
      background-color: #2a6916;
      border: 1px solid #525252;
      line-height: 52px;
      font-size: 24px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      box-shadow: 2px 3px 4px 1px #88888869;
      text-transform: uppercase;

      &:hover {
        cursor: pointer;
        background-color: #015c01;
      }

      &:active {
        cursor: pointer;
        background-color: #006300;
        box-shadow: 3px 4px 5px 2px #88888869;
      }
    }
  }
}

.bg-gray {
  background-color: #f1f1f1;
  height: 100%;
}
