.onoffswitch {
  position: relative;
  width: 50px;
  margin-top: 8px;
  -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}

.onoffswitch-checkbox {
    display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 14px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;

  &:before, &:after {
    float: left;
    width: 50%;
    height: 15px;
    padding-left: 6px;
    padding-top: 1px;
    line-height: 15px;
    font-size: 12px;
    color: #FFFFFF;
    font-family: Trebuchet, Arial, sans-serif;
    font-weight: 600;
    box-sizing: border-box;
  }

  &:before {
    content: "Yes";
    background-color: $primary-button;
    color: #FFFFFF;
  }

  &:after {
    content: "No";
    background-color: #9B9B9B;
    color: #FFFFFF;
    text-align: right;
    padding-right: 8px;
  }
}

.onoffswitch-switch {
  display: block;
  width: 20px;
  height: 20px;
  background: #FFFFFF;
  position: absolute;
  top: -3px;
  bottom: -3px;
  right: 32px;
  border-radius: 50%;
  transition: all 0.3s ease-in 0s;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  outline: none;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  background-color: #568F2B;
  right: 0px;
}
