.red-state {
  background-color: red !important;

  .box-widget-active-filter-button {
    color: white;
  }
}

.orange-state {
  background-color: orange !important;
}

.width-constrained .to-constrain {
  white-space: nowrap; 
  width: 60px; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.box-widget {
  .box-widget-body {
    height:100%;
    display: flex;
    text-align: center;

    .metric-container {
      display: flex;
      // flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      position: relative;
      text-align: center;
      width: 100%;
      height: 100%;
      margin: auto;
      padding: 0 5px 0 5px;

      .box-widget-metric {
        margin: auto 3px;
        width: 80px;
        background-color: inherit;

        h5 {
          height: 48px;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          width: inherit;
        }
        h3 {
          margin: 5px 0 0 0;

        }
      }

    }
  }
}
