.form-components-locations {


  .places-table {
    border: 0;
    margin-top: 0rem !important;

    span.ember-power-select-selected-item {
      margin-left: 2px;
    }

  }

  td.button-cell {
    width: 10px;
  }

  .btn {
    padding: 0 0.40rem;
    font-size: 0.9rem;
  }
}

.custom-cursor {
  cursor: url(/location-26.png) 12 25, pointer !important;
}
