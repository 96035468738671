.form-components-places {

  .places-table {
    border: none;
    margin-top: 0rem !important;

    span.ember-power-select-selected-item {
      margin-left: 2px;
    }

    .cell-label {
      padding: 0 0 0 9px;
    }
    .edit-label {
      padding: 0 0 0 0;
    }
    .button-cell {
      padding: 0 0 0 0;
    }
  }

  .places-inline-button {
    width: 20px;
    height: 21px;
    padding: 0 2px 0px 3px !important;
    font-size: $widget-button-font-size;

  }
}

.custom-cursor {
  cursor: url(/location-26.png) 12 25, pointer !important;
}

.map-btn {
  padding: 0px 10px;
}
