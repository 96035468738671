.filter-drawer {
  right: calc(100% - 400px);
}
.filter-settings-side-drawer {
  display: flex;
  flex-direction: column;

  overflow: hidden;
  overflow-y: auto;

  color: $text;
  font-family: $ui-font;

  margin: 0;

  .side-drawer-subheader {
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, .5);
    z-index: 5;

    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    font-size: 14px;
    font-family: $ui-font;
    padding: 5px 0px 5px 0px;
    .reorder-column {
      flex: 0 0 auto;
    }

    .visibility-column {
      flex: 0 0 auto;
      padding: 0 5px;
      cursor: pointer;
    }

    .header-label-column {
      flex: 0 1 33%;
      padding-left: 5px;
    }

    .filter-type-column{
      flex: 1 1 30%;
    }

    .header-button-outline {
      flex: 0 0 auto;
      border-radius: 2px;
      margin-bottom: 2px;
      margin-right: 18px;
      background-color: #F5F5F7;
      word-wrap: break-word;
      border-color: rgba(38, 50, 56, 0.2);

      &:disabled {
        cursor: not-allowed;
      }
    }

  }

  .side-drawer-table-wrapper {
    overflow-y: auto;

      // table-styling
      .filter-settings-table {
        padding: 7px 5px 0 0;
        width: 100%;

        font-weight: 500;

        background-color: $sidedrawer-table-background-color;
        border: none;

        .filter-setting-row {
          display: flex;
          flex-direction: row;
          flex-basis: calc(100vh - 34px - 34px - 70px);
          flex-grow: 0;
          flex-shrink: 0;
          z-index: 2;
          align-items: baseline;
          padding: 4px 0px 4px 0px;
          .setting-icon{
            flex: 0 1 auto;
          }
          .setting-item {
            flex: 1 0 25%;
          }
          .setting-item-type {
            flex: 0 0 20%;
          }
          .setting-item-value {
            flex: 0 1 38%;
          }
        }

        input {
          font-weight: 500;
        }
      }
  }

  .subheader-buttons {
    display: inline;
    position:relative;
    top: -3px;

    button {
      font-size: 14px;
      font-weight: 500;
      border: 2px $primary-button solid;
      border-radius: 5px;
      padding: 5px 25px 3px 25px;
    }

    .header-button {
      background-color: $primary-button;
      color: white;

      &:hover {
        background-color: $dds-green2;
      }
    }

    .header-button-outline {
      color: $primary-button;

      &:hover {
        background-color: $dds-green2;
        color: $header-dark-background;
      }
    }
  }

  .ember-power-select-status-icon {
    color: #000105;
  }


  // ember-sortable styling

  .handle {
    cursor: move;
  }

  .sortable-item {
    transition: all .125s !important;
    position: relative;

    &.is-dragging {
      transition-duration: 0s;
      background: $sidedrawer-table-background-color;
      z-index: 10;
    }

    &.is-dropping {
      background: $sidedrawer-table-background-color;
      z-index: 10;
    }
  }

  .reorder-column {
    width: 30px;
    text-shadow: 0px 1px 1px #4d4d4d;
    color: $sidedrawer-button-color;
    z-index: 2;
    padding-left: 15px;

    .reorder-handles {
      width: 10px;
      height: 15px;
    }
  }

  .visibility-column {
    padding: 0px 6px 0px 6px;
    z-index: 2;

  }

  .label-column {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0px 6px;
    font-size: $widget-input-font-size;
    font-weight: 500;
    z-index: 2;
  }

  .filter-type-column {
    padding: 0px 8px;
    z-index: 2;

  }

  .filter-value-column {
    z-index: 2;
    padding: 0px 15px 0px 0px;

  }

}
